import React, { useState, useEffect } from "react"
import { Rating, Link } from 'react-simple-star-rating'
import { useStaticQuery, graphql } from "gatsby"
import Googlelogo from '../../../images/google-logo.svg';

import './GoogleRating.scss'
// markup
const GoogleRating = (props) => {

    const data = useStaticQuery(graphql`
        query  {  
            glstrapi {
            globalConfig {
                Rating
                Total_Rating
            }
            }
        }
        `)
    let google = data.glstrapi.globalConfig;
    return (
        <React.Fragment>
            <div className="google-rating">
                <img src={Googlelogo} alt="googelogo" width={"120px"} />
                <a href={`/about/customer-reviews/`} className="d-flex align-items-center"> 
                <Rating readonly={true} size={20} initialValue={google.Rating} allowFraction={true}></Rating>
                <p>{google.Total_Rating} reviews</p>
                </a>
               
            </div>
        </React.Fragment >
    )
}
export default GoogleRating
